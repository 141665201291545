// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-header {
    width: 100%;
    height: 110px;
    border-bottom: 1px solid #8327ed94;
    position: fixed;
    top: 0px;
    display: flex;
    align-items: center;
    background-color: rgb(0, 0, 0);
    z-index: 999;
    padding: 0% 10%;
    cursor: pointer;
}


.img-header {
    width: 70px;
    height: 70px;
}

@media screen and (min-width: 768px) {
    .logo-header{
        padding: 0px 115px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/header/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kCAAkC;IAClC,eAAe;IACf,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,kBAAkB;IACtB;EACF","sourcesContent":[".logo-header {\n    width: 100%;\n    height: 110px;\n    border-bottom: 1px solid #8327ed94;\n    position: fixed;\n    top: 0px;\n    display: flex;\n    align-items: center;\n    background-color: rgb(0, 0, 0);\n    z-index: 999;\n    padding: 0% 10%;\n    cursor: pointer;\n}\n\n\n.img-header {\n    width: 70px;\n    height: 70px;\n}\n\n@media screen and (min-width: 768px) {\n    .logo-header{\n        padding: 0px 115px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
