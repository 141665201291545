import "./styles.css"
import Carousel from 'react-bootstrap/Carousel';
import {useEffect, useState} from "react";

export default function CarouselImages({images, loading, setLoading}){
    const [preloadedImages, setPreloadedImages] = useState([]);

    const loadImage = (source) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(source);
            img.onerror = reject;
            img.src = source;
        });
    };

    useEffect(() => {
        Promise.all(images?.map(image => loadImage(image)))
            .then(setPreloadedImages, setLoading(false))
            .catch(console.error); // handle loading errors
    }, [images]);

    return (
        <Carousel data-bs-theme="dark">
            {
                !loading
                    &&
                    preloadedImages.length > 0 &&
                        preloadedImages?.map((img, index) =>
                            <Carousel.Item key={`${img}${index}`} as='div' className="carousel-item">
                                <img className='product-img' alt='teste' src={img}/>
                            </Carousel.Item>
                        )
            }
        </Carousel>
    )
}