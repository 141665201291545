import { useParams } from "react-router-dom";
import BackButton from "../../components/backButton";
import CarouselImages from "../../components/carouselImages";
import SelectColor from "../../components/selectColor"
import SelectStorage from "../../components/selectStorage"
import PaymentOptions from "../../components/paymentOptions"
import "./styles.css"
import { useEffect, useState } from "react";
import api from "../../services/api";

export default function Product() {
    let {categoryName, id} = useParams()
    const [endScroll, setEndScroll] = useState(false)
    const [colorId, setColorId] = useState()
    const [productImages, setProductImages] = useState([])
    const [loading, setLoading] = useState(true)
    const [storageId, setStorageId] = useState()
    const [product, setProduct] = useState([])
    const [valueProduct, setValueProduct] = useState({})

    useEffect(()=>{
        api.get(`products/${id}`)
            .then(response => {
                setProduct(response.data)
                setProductImages(new Array(response.data.defaultImg))
            }).finally(setLoading(false))
    }, [id])

    function userReachedBottom() {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.offsetHeight;
        return scrollPosition >= documentHeight;
    }

    window.addEventListener('scroll', () => {
        if (userReachedBottom()) {
            setEndScroll(true)
        }else{
            setEndScroll(false)
        }
    });
    return(
        <>
            <BackButton route={`/${categoryName}/products`}/>
            {
                !loading
                &&
                <>
                    <h2 className="product-name">{product.name}</h2>
                    <div className="product-content">
                        <div className="carousel-images">
                            <CarouselImages
                                images={productImages}
                                loading={loading}
                                setLoading={setLoading}
                            />
                        </div>
                        <div className="product-infos">
                            {
                                product?.colors?.length > 0 &&
                                <SelectColor
                                    colors={product.colors}
                                    setColorId={setColorId}
                                    colorId={colorId}
                                    setProductImages={setProductImages}
                                />
                            }
                            {
                                product?.storages?.length > 0 &&
                                <SelectStorage
                                    storages={product.storages}
                                    setStorageId={setStorageId}
                                    storageId={storageId}
                                />
                            }
                            {
                                (colorId && storageId && valueProduct && !endScroll) &&
                                <a className="ca3-scroll-down-link ca3-scroll-down-arrow" data-ca3_iconfont="ETmodules"
                                   data-ca3_icon=""></a>
                            }
                            {
                                (colorId && storageId) &&
                                <PaymentOptions
                                    colorId={colorId}
                                    storageId={storageId}
                                    valueProduct={valueProduct}
                                    setValueProduct={setValueProduct}
                                />

                            }
                        </div>
                    </div>
                </>
            }

        </>
    )
}