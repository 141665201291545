// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-installments{
    width: 40%;
    min-width: 300px;
    padding-top: -10px;
}
.accordion-item{
    background-color: rgb(0, 0, 0);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.accordion-button, .accordion-button:not(.collapsed), .accordion-body{
    color: white;
}
.accordion-button{
    background-color: #0e0e16;
    padding: 10px 0;
}
.accordion-button:not(.collapsed){
    color: white;
    background-color: #0e0e16;
}
.accordion-item{
    border: 0 solid white;
}
.accordion-body, .accordion-collapse{
    background-color: rgb(0, 0, 0);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.accordion-button:focus{
    outline: none;
    border-color: none;
    box-shadow: 0 0 0 0 white;
}
.accordion-button::after {
    filter: brightness(0) invert(1);
}

.accordion-header{
    border-bottom: 1px solid #d2d2d7;
}`, "",{"version":3,"sources":["webpack://./src/components/installments/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,gCAAgC;AACpC;AACA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,+BAA+B;AACnC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".product-installments{\n    width: 40%;\n    min-width: 300px;\n    padding-top: -10px;\n}\n.accordion-item{\n    background-color: rgb(0, 0, 0);\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n}\n.accordion-button, .accordion-button:not(.collapsed), .accordion-body{\n    color: white;\n}\n.accordion-button{\n    background-color: #0e0e16;\n    padding: 10px 0;\n}\n.accordion-button:not(.collapsed){\n    color: white;\n    background-color: #0e0e16;\n}\n.accordion-item{\n    border: 0 solid white;\n}\n.accordion-body, .accordion-collapse{\n    background-color: rgb(0, 0, 0);\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n}\n.accordion-button:focus{\n    outline: none;\n    border-color: none;\n    box-shadow: 0 0 0 0 white;\n}\n.accordion-button::after {\n    filter: brightness(0) invert(1);\n}\n\n.accordion-header{\n    border-bottom: 1px solid #d2d2d7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
