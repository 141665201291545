// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  background-color: #0e0e16;
  font-family: 'SF Pro Display', sans-serif;
	font-size: 14px;
	color: white;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #4b1f7ea2 #0e0e16;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #0e0e16;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #4b1f7ea2;
    border-radius: 10px;
    border: 5px solid #0e0e16;
  }

.main{
  padding-left:10%;
  min-height: 100vh;
  width: 100%;
  padding-right: 10%;
  padding-top: 180px;
  padding-bottom: 110px;
}

@media screen and (min-width: 768px) {
    .main{
        padding-left:115px;
        padding-right: 115px;
        padding-top: 220px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AACA;EACE,yBAAyB;EACzB,yCAAyC;CAC1C,eAAe;CACf,YAAY;AACb;AACA,8BAA8B;EAC5B,YAAY;EACZ;IACE,qBAAqB;IACrB,kCAAkC;EACpC;;EAEA,6BAA6B;EAC7B;IACE,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;EAC3B;;AAEF;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;IACI;QACI,kBAAkB;QAClB,oBAAoB;QACpB,kBAAkB;IACtB;EACF","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\nbody{\n  background-color: #0e0e16;\n  font-family: 'SF Pro Display', sans-serif;\n\tfont-size: 14px;\n\tcolor: white;\n}\n/* ===== Scrollbar CSS ===== */\n  /* Firefox */\n  * {\n    scrollbar-width: auto;\n    scrollbar-color: #4b1f7ea2 #0e0e16;\n  }\n\n  /* Chrome, Edge, and Safari */\n  *::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: #0e0e16;\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background-color: #4b1f7ea2;\n    border-radius: 10px;\n    border: 5px solid #0e0e16;\n  }\n\n.main{\n  padding-left:10%;\n  min-height: 100vh;\n  width: 100%;\n  padding-right: 10%;\n  padding-top: 180px;\n  padding-bottom: 110px;\n}\n\n@media screen and (min-width: 768px) {\n    .main{\n        padding-left:115px;\n        padding-right: 115px;\n        padding-top: 220px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
