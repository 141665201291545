// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-img{
    height: auto;
    max-width: 100%;
    border-radius: 20px;
}
.carousel-inner{
    background-color: #F5F5F7;
    border-radius: 20px;
}
.carousel-item{
    background-color: #F5F5F7;
    height: 100%;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/carouselImages/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".product-img{\n    height: auto;\n    max-width: 100%;\n    border-radius: 20px;\n}\n.carousel-inner{\n    background-color: #F5F5F7;\n    border-radius: 20px;\n}\n.carousel-item{\n    background-color: #F5F5F7;\n    height: 100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
