import { CategoryProvider } from "./category";
import { ProductProvider } from "./product";



const Providers = ({children})=>{
    return (
        <CategoryProvider>
            <ProductProvider>
                {children}
            </ProductProvider>
        </CategoryProvider>
    )
}

export default Providers