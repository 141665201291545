// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 30px;
}

.storage{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 20px 20px;
    cursor: pointer;
    transition: border 0.5s;
    border: 2px solid #d2d2d7;
}

.storage-selected{
    transition: border 0.5s;
    border: 2px solid #491683;
}

.storage-capacity{
    all: unset;
    font-size: 18px;
}
.required-field{
    font-size: 14px;
    color: rgb(176, 48, 48);
}

@media screen and (min-width: 768px) {
    .storage{
        padding: 35px 20px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/selectStorage/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI;QACI,kBAAkB;IACtB;;AAEJ","sourcesContent":[".storage-content{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    gap: 30px;\n}\n\n.storage{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    border-radius: 20px;\n    padding: 20px 20px;\n    cursor: pointer;\n    transition: border 0.5s;\n    border: 2px solid #d2d2d7;\n}\n\n.storage-selected{\n    transition: border 0.5s;\n    border: 2px solid #491683;\n}\n\n.storage-capacity{\n    all: unset;\n    font-size: 18px;\n}\n.required-field{\n    font-size: 14px;\n    color: rgb(176, 48, 48);\n}\n\n@media screen and (min-width: 768px) {\n    .storage{\n        padding: 35px 20px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
