import Header from "./components/header";
import './styles.css'
import './reset.css'
import 'react-loading-skeleton/dist/skeleton.css'
import Routes from "./pages/routes";
import {SkeletonTheme} from "react-loading-skeleton";


function App() {
  return (
   <SkeletonTheme baseColor ="#202020"  highlightColor ="#444" >
        <Header/>
        <Routes/>
    </SkeletonTheme>
  )
}

export default App
