import "./styles.css"
import Accordion from 'react-bootstrap/Accordion';

export default function Installments({ valueProduct }){

    return(
        <div className="product-installments">
            <Accordion variant="dark">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Em até {valueProduct?.installmentsCredit?.length}
                        x de R$ {
                        (
                            (
                                (valueProduct?.totalValue - valueProduct?.entryValue)
                                *
                                (valueProduct?.installmentsCredit[valueProduct?.installmentsCredit.length-1]?.fees / 100)
                                +(valueProduct?.totalValue - valueProduct?.entryValue)
                            )
                           / valueProduct?.installmentsCredit[valueProduct?.installmentsCredit.length-1]?.number
                        )?.
                        toLocaleString('pt-br', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}

                    </Accordion.Header>
                    <Accordion.Body>
                        {
                            valueProduct?.installmentsCredit?.map(installment =>
                                <p key={installment?.number}>
                                    {installment?.number} x de R$
                                    {((
                                        (
                                            (valueProduct?.totalValue - valueProduct?.entryValue) *
                                            (installment?.fees / 100))
                                            +(valueProduct?.totalValue - valueProduct?.entryValue)
                                        ) / installment?.number
                                    ).toLocaleString('pt-br', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</p> )
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}
