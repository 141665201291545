import { useHistory } from "react-router-dom"
import "./styles.css"


export default function BackButton({route}){
    let history = useHistory()

    return (
        <button className="back-button" onClick={()=> history.push(route)}>
            Voltar
        </button>
    )
}