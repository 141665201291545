// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-color-select{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.color-select{
    all: unset;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    list-style: none;
}
.color-item{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.color-label-img{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 40px;
    background-color: white;
}
.color-selected{
    transition: 0.3s;
    border: 3px solid #491683;
    width: 32px;
    height: 32px;
}
.color-not-selected{
    transition: 0.3s;
    border: 3px solid #0e0e16;
}
.color-input-img{
    width: 22px;
    height: 22px;
    border-radius: 30px;
}
.color-name{
    font-size: 17px;
}
.required-field{
    font-size: 14px;
    color: rgb(176, 48, 48);
}


`, "",{"version":3,"sources":["webpack://./src/components/selectColor/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,UAAU;IACV,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".content-color-select{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n.color-select{\n    all: unset;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 10px;\n    list-style: none;\n}\n.color-item{\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n}\n.color-label-img{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    width: 32px;\n    height: 32px;\n    border-radius: 40px;\n    background-color: white;\n}\n.color-selected{\n    transition: 0.3s;\n    border: 3px solid #491683;\n    width: 32px;\n    height: 32px;\n}\n.color-not-selected{\n    transition: 0.3s;\n    border: 3px solid #0e0e16;\n}\n.color-input-img{\n    width: 22px;\n    height: 22px;\n    border-radius: 30px;\n}\n.color-name{\n    font-size: 17px;\n}\n.required-field{\n    font-size: 14px;\n    color: rgb(176, 48, 48);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
