import './styles.css'
import logo from '../../assets/img/logo.png'
import { useHistory } from 'react-router-dom'


export default function Header() {
    let history = useHistory()

    return(
        <div className='logo-header' onClick={()=> history.push("/")}>
            <img className='img-header' src={logo} alt='logo'/>
        </div>
    )
}
