// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-total-content{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.product-total-value-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.product-total-value-pix{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    color: #8327ed;
}
.product-total-value{
    font-size: 33px;
    margin: 0;
}
.product-total-value-debit{
    margin-bottom:  0;
}

.product-total-entry-value-number{
    all: unset;
    border: 2px solid #d2d2d7;
    border-radius: 7px;
    padding: 10px 10px 10px 7px;
    width: 100%;
    min-width: 150px;
    max-width: 180px;
}

.separator{
    width: 100%;
    border-bottom: 1px solid #d2d2d7;
}`, "",{"version":3,"sources":["webpack://./src/components/paymentOptions/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;IACrB,SAAS;IACT,cAAc;AAClB;AACA;IACI,eAAe;IACf,SAAS;AACb;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gCAAgC;AACpC","sourcesContent":[".product-total-content{\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n}\n.product-total-value-content{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.product-total-value-pix{\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-end;\n    gap: 10px;\n    color: #8327ed;\n}\n.product-total-value{\n    font-size: 33px;\n    margin: 0;\n}\n.product-total-value-debit{\n    margin-bottom:  0;\n}\n\n.product-total-entry-value-number{\n    all: unset;\n    border: 2px solid #d2d2d7;\n    border-radius: 7px;\n    padding: 10px 10px 10px 7px;\n    width: 100%;\n    min-width: 150px;\n    max-width: 180px;\n}\n\n.separator{\n    width: 100%;\n    border-bottom: 1px solid #d2d2d7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
