import { useState } from "react"
import "./styles.css"

export default function SelectColor({ colors, setColorId, colorId, setProductImages}) {
    const [colorName, setColorName] = useState("")

    const setColorIdAndColorName = (color)=>{
        setColorName(` - ${color.name}`)
        setColorId(color.id)
        setProductImages(color.imgUrl)
    }

    return (
        <div className="content-color-select">
            <h4>Selecione sua cor favorita {!colorId &&<b className="required-field"> - Obrigatório</b>}</h4>
            <h5 className="color-name">Cor{colorName}</h5>
            <ul className="color-select">
                {
                    colors.map(color => 
                        <li 
                            className="color-item"
                            onClick={()=> setColorIdAndColorName(color)}
                            key={color.id}
                        >
                            <label 
                                    className={`color-label-img ${colorId === color.id ? "color-selected":"color-not-selected"}`}
                                >
                                <img 
                                    alt=""
                                    src={color.img}
                                    className={`color-input-img`}
                                />
                            </label>
                        </li>
                    )
                }
            </ul>

        </div>
    )
}