import "./styles.css"

export default function SelectStorage({ storages, setStorageId, storageId }){
    return (
        <div className="storage-content">
            <div>
                <h4>Selecione o armazenamento desejado{!storageId && <b className="required-field"> - Obrigatório</b>}</h4>
            </div>
            {
                storages.map((storage)=> 
                    <div 
                        key={storage.id}
                        className={`storage ${storageId === storage.id ? "storage-selected":"storage-not-selected"}`}
                        onClick={()=> setStorageId(storage.id)}
                    >
                        <p className="storage-capacity">{storage.capacity} {storage.name}</p>
                    </div>
                )
            }
        </div>
    )
}