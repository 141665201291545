// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-category{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 100px;
}
.card-category{
    display: flex;
    justify-content: center;
    width: 250px;
    align-items: flex-end;
    height: 320px;
    border-radius: 20px;
    color: #000;
    background-size: 100%;
    cursor: pointer;
}

.img-category{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;

}
.name-category {
    color: white;
    font-size: 26px;
    position: absolute;
    z-index: 2;
    padding-bottom: 20px;
    text-shadow: 0px 0px 9px rgba(0,0,0, 100);
}`, "",{"version":3,"sources":["webpack://./src/components/categoryCard/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,UAAU;AACd;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,UAAU;;AAEd;AACA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,oBAAoB;IACpB,yCAAyC;AAC7C","sourcesContent":[".content-category{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    gap: 100px;\n}\n.card-category{\n    display: flex;\n    justify-content: center;\n    width: 250px;\n    align-items: flex-end;\n    height: 320px;\n    border-radius: 20px;\n    color: #000;\n    background-size: 100%;\n    cursor: pointer;\n}\n\n.img-category{\n    width: 100%;\n    height: 100%;\n    border-radius: 20px;\n    z-index: 1;\n\n}\n.name-category {\n    color: white;\n    font-size: 26px;\n    position: absolute;\n    z-index: 2;\n    padding-bottom: 20px;\n    text-shadow: 0px 0px 9px rgba(0,0,0, 100);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
