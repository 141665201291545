import { useEffect, useState, useContext } from 'react';
import './styles.css'
import { useHistory } from "react-router-dom";
import api from '../../services/api';
import { CategoryContext } from "../../contexts/category";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CategoryCard() {
        
    let history = useHistory(); 
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const {setCategory} = useContext(CategoryContext)

    useEffect(()=>{
        api.get("categories")
            .then(response => setCategories(response.data))
            .finally(setLoading(false))
    }, [])

    const handleSubmit = (category) =>{
        setCategory(category)
        history.push(category.route)
    }

    return(
        <div className='content-category'>
            {
                loading ?
                    <div className='card-category'>
                        <Skeleton width={250} height={320} borderRadius={20}/>
                    </div>
                    :
                    categories?.map(category =>
                        <div
                            className='card-category'
                            onClick={() => {
                                handleSubmit(category)
                            }}
                            key={category.id}
                        >
                            <img className='img-category' src={category.img} alt='' width={100} height={100}/>
                            <h2 className='name-category'>{category.name}</h2>
                        </div>
                    )


            }

        </div>
    )
}