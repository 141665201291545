import { useEffect, useState } from "react";
import BackButton from "../../components/backButton";
import "./styles.css"
import Select from 'react-select'
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Products() {
    const [productRoute, setProductRoute] = useState("")
    const [products, setProducts] =  useState([])
    const [isValid, setIsValid] = useState(false)
    const history = useHistory(); 
    const { categoryName }= useParams()

    useEffect(()=>{
        api.get(`categories/${categoryName}/products`)
        .then(response => {
            response.data.forEach(product => {
                product.value = product.id
                product.label = product.name
            })
            setProducts(response.data)
        })
    }, [])

    const colourStyles = {
        control: (styles) => ({ 
            ...styles,
            border:'2px solid #8327ed94;',
            height: '45px'
        })
    };

    const setRoute = (product)=>{
        let route = `/${categoryName}/products/${product.routeName}`
        setProductRoute(route)
        if(product.routeName.length > 0){
            setIsValid(true)
        }else{
            setIsValid(false)
        }
    }

    return(
        <>
        <BackButton route="/"/>
        <div className='content-product'>
            <div className="select-product-content">
                <Select 
                    options={products} 
                    styles={colourStyles}
                    placeholder="Selecione"
                    classNamePrefix= "select-product"
                    onChange={(product)=> setRoute(product)}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                        primary25: '#3c008092',
                        primary: '#8327ed94;'
                        }
                    })}
                />
            </div>
            <button
                className="button-select" 
                onClick={()=> history.push(productRoute)}
                disabled={!isValid}
            >
                Ver produto
            </button>
        </div>
        </>
    )
}