// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-product{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
}
.select-product-content{
    width: 100%;
    color: white;
}

.button-select{
    all: unset;
    text-align: center;
    width: 50%;
    border-radius: 30px;
    height: 45px;
    cursor: pointer;
    transition: 0.3s;
    background-color: #8327ed94;
    color: white;
    max-width: 175px;
}
.button-select:disabled{
    transition: 0.3s;
    cursor: not-allowed;
    background-color: #9d6fd13f;
}
.select-product__menu{
    border: 1px solid #8327ed94;
    margin-top: 15px !important;
}
.select-product__menu-list{
    background-color: #000
}


@media screen and (min-width: 650px) {
    .content-product{
        display: flex;
        flex-wrap: nowrap;
    }
    .select-product-content{
        width: 60%;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/pages/products/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,SAAS;AACb;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B;AACA;IACI;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,iBAAiB;IACrB;IACA;QACI,UAAU;IACd;EACF","sourcesContent":[".content-product{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    gap: 40px;\n}\n.select-product-content{\n    width: 100%;\n    color: white;\n}\n\n.button-select{\n    all: unset;\n    text-align: center;\n    width: 50%;\n    border-radius: 30px;\n    height: 45px;\n    cursor: pointer;\n    transition: 0.3s;\n    background-color: #8327ed94;\n    color: white;\n    max-width: 175px;\n}\n.button-select:disabled{\n    transition: 0.3s;\n    cursor: not-allowed;\n    background-color: #9d6fd13f;\n}\n.select-product__menu{\n    border: 1px solid #8327ed94;\n    margin-top: 15px !important;\n}\n.select-product__menu-list{\n    background-color: #000\n}\n\n\n@media screen and (min-width: 650px) {\n    .content-product{\n        display: flex;\n        flex-wrap: nowrap;\n    }\n    .select-product-content{\n        width: 60%;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
