// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button{
    all: unset;
    position: absolute;
    top: 105px;
    left: 10%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-top: 20px;
    font-size: 20px;
    width: 90px;
    height: 35px;
    color: white;
    text-align: center;
    border-bottom: 2px solid #8327ed94;
    transition: border-bottom 500ms linear 0s;
}

.back-button:hover{
    border-bottom: 2px solid #ffffff;
}

@media screen and (min-width: 1200px) {
    .back-button{
        left: 115px;
        position: fixed;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/backButton/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,eAAe;IACf,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kCAAkC;IAClC,yCAAyC;AAC7C;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,WAAW;QACX,eAAe;IACnB;EACF","sourcesContent":[".back-button{\n    all: unset;\n    position: absolute;\n    top: 105px;\n    left: 10%;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    margin-top: 20px;\n    font-size: 20px;\n    width: 90px;\n    height: 35px;\n    color: white;\n    text-align: center;\n    border-bottom: 2px solid #8327ed94;\n    transition: border-bottom 500ms linear 0s;\n}\n\n.back-button:hover{\n    border-bottom: 2px solid #ffffff;\n}\n\n@media screen and (min-width: 1200px) {\n    .back-button{\n        left: 115px;\n        position: fixed;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
