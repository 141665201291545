import { Switch, Route} from "react-router-dom";
import Home from "./home";
import Product from "./product";
import Products from "./products";


export default function Routes(){
    return (
        <main className="main">
          <Switch>
              <Route exact path="/:categoryName/products/:name/:id" >
                <Product />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/:categoryName/products">
                <Products />
              </Route>
          </Switch>
        </main>
    )
}