import { useEffect, useState } from "react"
import "./styles.css"
import Installments from "../installments"
import api from "../../services/api"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

export default function PaymentOptions({ valueProduct, setValueProduct,colorId, storageId}){
    let {id} = useParams()
    const [entryValue, setEntryValue] = useState("R$ 0,00")
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        let totalValue = valueProduct?.totalValue - valueProduct.entryValue
        let feeAmount = (totalValue * (valueProduct?.installmentDebit?.fees/ 100))
        setTotalAmount(parseInt(totalValue.toString()) + feeAmount)
    }, [valueProduct?.totalValue,valueProduct?.installmentDebit?.fees, totalAmount]);


    const setEntryValueAndMask = (inputValue) => {
        let stringEntryValue = inputValue.replace('.', '').replace(',', '').replace(/\D/g, '') / 100
        let floatedEntryValue =  parseFloat(stringEntryValue)
        let totalValueLessEntryValue = valueProduct.price - floatedEntryValue
        
        valueProduct.totalValue = totalValueLessEntryValue < 0 ? 0 : totalValueLessEntryValue
        setValueProduct(valueProduct)

        const options = { minimumFractionDigits: 2 }
        const result = new Intl.NumberFormat('pt-BR', options).format(floatedEntryValue)

        setEntryValue("R$ "+result)
      }

    useEffect(()=>{
        api.post(`/products/price`, {productId:id, storageId, colorId})
        .then(response => {
            response.data.entryValue = 0
            response.data.totalValue = parseInt(response.data.price)
            response.data.installmentFees = response.data.installmentFees / 100
            setValueProduct(response.data)
        })
    }, [storageId,colorId])

    return (
        <>
            {valueProduct &&
                (
                    <div className="product-total-content">
                        <div className="separator"></div>
                        <div className="product-total-entry">
                            <h5 className="product-total-entry-value">
                                Entrada
                            </h5>
                            <input
                                type="currency"
                                className="product-total-entry-value-number"
                                onChange={(value) => setEntryValueAndMask(value.target.value)}
                                value={entryValue}
                            />
                        </div>
                        <div className="product-total-value-content">
                            <div className="product-total-value-pix">
                                <h2 className="product-total-value">
                                    R$ {valueProduct?.totalValue?.toLocaleString('pt-br', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                                </h2>
                                <h5>no Pix</h5>
                            </div>
                            <div>
                                <h6 className="product-total-value-debit">
                                    R$ {totalAmount?.toLocaleString('pt-br', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} no Débito
                                </h6>
                            </div>
                            {
                                valueProduct?.installmentsCredit?.length > 0 &&
                                <Installments
                                    valueProduct={valueProduct}
                                />
                            }

                        </div>
                    </div>
                )
            }
        </>
    )
}